// src/router/index.js
import { createWebHistory, createRouter } from "vue-router";
import routes from "./routes";
import appConfig from "../app.config.json";

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const authRequired = to.matched.some((record) => record.meta.authRequired);
  const isAuthPage = to.matched.some((record) => record.meta.authPage);
  const isAuthenticated = !!localStorage.getItem("accessToken");

  // Si está autenticado e intenta acceder a páginas de auth
  if (isAuthenticated && isAuthPage) {
    next("/");
    return;
  }

  // Si requiere auth y no está autenticado
  if (authRequired && !isAuthenticated) {
    next("/login");
    return;
  }

  next();
});

router.beforeResolve(async (to, from, next) => {
  document.title = to.meta.title + " | " + appConfig.title;
  next();
});

export default router;
